<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Using the following total ionic equation, identify which species would be classified as
        <b>spectator ions</b>:
      </p>

      <p class="mb-2 pl-6">
        <chemical-latex :content="rxn" />
      </p>

      <div v-for="(option, i) in options" :key="'pt-1-' + i" class="mb-3">
        <v-checkbox v-model="inputs.input1" hide-details :value="i">
          <template v-slot:label>
            <chemical-latex :content="option" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question390',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      spectatorOptions: [
        ['CH3COO-', 'Cr^2+', 'Cr(OH)2', 'K+', 'OH-'],
        ['H2O', 'H+', 'Na+', 'OH-', 'SO4^2-'],
        ['Ba^2+', 'BaSO4', 'Cl-', 'Na+', 'SO4^2-'],
        ['Ag+', 'AgCl', 'Cl-', 'Na+', 'NO3-'],
      ],
      rxnOptions: [
        'Cr^2+(aq) + 2CH3COO-(aq) + 2K+(aq) + 2OH-(aq) -> Cr(OH)2(s) + 2K+(aq) + 2CH3COO-(aq)',
        '2H+(aq) + SO4^2-(aq) + 2Na+(aq) + 2OH-(aq) -> 2H2O(l) + 2 Na+(aq) + SO4^2-(aq)',
        'Ba^2+(aq) + 2Cl-(aq) + 2 Na+(aq) + SO4^2-(aq) -> 2Na+(aq) + 2Cl-(aq) + BaSO4(s)',
        'Ag+(aq) + NO3-(aq) + Na+(aq) + Cl-(aq) -> AgCl(s) + Na+(aq) + NO3-(aq)',
      ],
    };
  },
  computed: {
    rxnNumber1() {
      return this.taskState.getValueBySymbol('rxnNumber1').content;
    },
    rxn() {
      return this.rxnOptions[this.rxnNumber1.value - 1];
    },
    options() {
      return this.spectatorOptions[this.rxnNumber1.value - 1];
    },
  },
};
</script>
